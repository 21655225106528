const TimelineData = [
    {
        id: 1,
        title: "BTG Pactual Colombia",
        position: "Fullstack Developer | QA Analyst",
        description:
            "Created a project to optimize duplicate database stored procedures with React and Python. Supported the technology team with issue documentation in Confluence and issue management in Jira.",
        date: "July 2022 - January 2023",
        icon: "btg",
        link: "https://www.linkedin.com/company/btg-pactual-col/",
    },
    {
        id: 2,
        title: "Sportta Group",
        position: "Fullstack Developer",
        description:
            "Developed and maintained the web portal and mobile app using React-React Native and Laravel through Scrum and Git Flow methodology.",
        date: "January 2023 - May 2023",
        icon: "sportta",
        link: "https://www.linkedin.com/company/grupo-sportta/",
    },
    {
        id: 3,
        title: "Alternova Inc",
        position: "Backend Developer",
        description:
            "Led the transformation of backend development processes on several projects, using clean coding practices and object-oriented programming principles in Python and PHP to improve system performance and maintainability.",
        date: "May 2023 - Currently",
        icon: "alternova",
        link: "https://www.linkedin.com/company/alternova-inc/mycompany/",
    },
];

export default TimelineData;
